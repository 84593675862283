<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Asignación Contable</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">Asignación Contable</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div
                class="card-header pt-2 pb-2"
                v-if="$store.getters.can('cs.asignacionesContables.create')"
              >
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        data-toggle="modal"
                        data-target="#modal_form"
                        @click="$refs.CsAsignacionContableForm.getDatosModal()"
                        v-if="
                          $store.getters.can('cs.asignacionesContables.create')
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        <label>Imputación</label>
                        <input
                          v-model="filtros.nombre"
                          type="text"
                          class="form-control form-control-sm"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        <label> Bloque </label>
                        <v-select
                          v-model="slct_bloque"
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          placeholder="Bloques"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="bloques"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th style="width: 12em;">
                        <label>Estado</label>
                        <select
                          v-model="filtros.estado"
                          class="form-control form-control-sm"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th style="width: 10em;">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="asignacion in asignaciones.data"
                      :key="asignacion.id"
                    >
                      <td>{{ asignacion.nombre }}</td>
                      <td>
                        {{ asignacion.bloque ? asignacion.bloque.nombre : "" }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            asignacion.estado == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                        >
                          {{ asignacion.nEstado }}
                        </span>
                      </td>
                      <td>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-secondary"
                            data-toggle="modal"
                            data-target="#modal_funcionario"
                            v-if="
                              $store.getters.can(
                                'cs.asignacionesContables.edit'
                              )
                            "
                            @click="
                              $refs.CsAsignacionContableFuncionarios.getDatosModal(
                                asignacion
                              )
                            "
                          >
                            <i class="fas fa-users"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal_form"
                            v-if="
                              $store.getters.can(
                                'cs.asignacionesContables.edit'
                              )
                            "
                            @click="
                              $refs.CsAsignacionContableForm.getDatosModal(
                                asignacion
                              )
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-info"
                            data-toggle="modal"
                            data-target="#modal_contables"
                            v-if="
                              $store.getters.can(
                                'cs.asignacionesContables.edit'
                              ) && asignacion.estado == 1
                            "
                            @click="
                              $refs.CsAsignacionContableCecoAfe.getDatosModal(
                                asignacion
                              )
                            "
                          >
                            <i class="fas fa-dollar-sign"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can(
                                'cs.asignacionesContables.delete'
                              ) && asignacion.estado == 2
                            "
                            @click="destroy(asignacion.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="asignaciones.total">
                  <p>
                    Mostrando del <b>{{ asignaciones.from }}</b> al
                    <b>{{ asignaciones.to }}</b> de un total:
                    <b>{{ asignaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="asignaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <CsAsignacionContableForm ref="CsAsignacionContableForm" />
    <CsAsignacionContableCecoAfe ref="CsAsignacionContableCecoAfe" />
    <CsAsignacionContableFuncionarios ref="CsAsignacionContableFuncionarios" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import pagination from "laravel-vue-pagination";
import CsAsignacionContableForm from "./CsAsignacionContableForm";
import CsAsignacionContableCecoAfe from "./CsAsignacionContableCecoAfe";
import CsAsignacionContableFuncionarios from "./CsAsignacionContableFuncionarios";

export default {
  name: "CsAsignacionContableIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    CsAsignacionContableForm,
    CsAsignacionContableCecoAfe,
    CsAsignacionContableFuncionarios,
  },

  data() {
    return {
      cargando: false,
      //id: null,
      asignaciones: {},
      listasForms: {
        estados: [],
      },
      bloques: [],
      funcionarios: [],
      slct_bloque: [],
      filtros: {
        nombre: null,
        estado: null,
      },
    };
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      const me = this;

      this.filtros.bloque_id = null;
      if (this.slct_bloque) {
        this.filtros.bloque_id = this.slct_bloque.id;
      }
      axios
        .get("/api/cs/asignacionesContables?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.asignaciones = response.data;
        })
        .catch(function(error) {
          me.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    buscarBloques() {
      let me = this;
      var url = "api/admin/bloques/lista";
      axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.bloques = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarFuncionario(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/funcionarios/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.funcionarios = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios({
          method: "POST",
          url: "/api/cs/asignacionesContables/otrosis",
          data: this.form,
        })
          .then(() => {
            this.getIndex();
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "El otrosí se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(id) {
      this.$swal({
        title: "Está seguro de eliminar esta Asignación Contable?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      })
        .then((result) => {
          if (result.value) {
            this.cargando = true;
            axios.delete("/api/cs/asignacionesContables/" + id).then(() => {
              this.cargando = false;
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se eliminó la asignación contable exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
          }
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.buscarBloques();
  },
};
</script>
