<template>
  <div class="hold-transition">
    <div class="modal fade" id="modal_funcionario">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              {{ modal_title }} / Asignación Contable
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalForm"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-11">
                <label for="funcionarios">Funcionario</label>
                <v-select
                  :class="[
                    $v.form.funcionario.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.funcionario"
                  placeholder="Funcionario..."
                  label="nombres"
                  class="form-control form-control-sm p-0"
                  :options="$parent.funcionarios"
                  :filterable="true"
                  @search="$parent.buscarFuncionario"
                ></v-select>
              </div>
              <div
                class="col-md-1 d-flex  align-items-center"
                v-if="!this.$v.form.funcionario.$invalid"
              >
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="asignOrRevokeFuncionario(1, null)"
                >
                  <i class="fas fa-user-plus"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div class="modal-body pt-0">
              <table
                class="table table-bordered table-striped table-hover table-sm text-xs mb-0"
              >
                <thead class="bg-dark text-center">
                  <tr class="text-nowarp">
                    <th>Nombre Funcionario</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="funcionario in asignaciones.funcionarios"
                    :key="funcionario.id"
                  >
                    <td class="text-center p-1">
                      {{ funcionario.nombres + " " + funcionario.apellidos }}
                    </td>
                    <td class="text-center p-1">
                      <div class="btn-group">
                        <button
                          class="btn bg-danger"
                          @click="asignOrRevokeFuncionario(2, funcionario.id)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "CsAsignacionContableFuncionarios",
  components: {
    vSelect,
  },
  data() {
    return {
      cargando: false,
      modal_title: "",
      form: {
        accion: null,
        funcionario_id: null,
      },
      asignaciones: [],
    };
  },
  validations() {
    return {
      form: {
        funcionario: {
          required,
        },
      },
    };
  },
  methods: {
    async getDatosModal(asignacion = null) {
      this.resetForm();
      this.$parent.cargando = true;
      this.modal_title = `Asignar Funcionario`;
      this.asignaciones = asignacion;
      this.form.cs_asignacion_contable_id = asignacion.id;
      this.asignaciones.funcionario;
      this.$parent.cargando = false;
    },

    resetForm() {
      this.form = {
        funcionario: null,
      };
    },

    async asignOrRevokeFuncionario(accion, funcionario_id) {
      this.$parent.cargando = true;
      this.form.accion = accion;
      this.form.funcionario_id = this.form.funcionario
        ? this.form.funcionario.id
        : funcionario_id;
      await axios({
        method: `PUT`,
        url: "/api/cs/asignacionesContables/Funcionarios",
        data: this.form,
      })
        .then(async (response) => {
          this.$parent.cargando = true;
          this.$parent.getIndex();
          await this.getDatosModal(response.data.asignacion);
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title:
              "El funcionario " +
              this.form.funcionario.nombres +
              " ya se encuentra asignado",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
  mounted() {
    this.getDatosModal();
  },
};
</script>
